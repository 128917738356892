.carousel-inner {
  background-color: black;

  position: relative;
  /*
  height: 400px;
  min-height: 300px;
  */
  max-height: 400px;

}
.carousel-inner img {
  width: 100%;
  height: 100%;
}
.carousel-item {
  margin-top: auto;
}

.carousel-inner img {
  display: block;
  height: auto;
  max-width: 100%;
}

.carousel-inner img {
  position: relative;
  overflow: hidden;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  height: 25%;
  max-height: 400px;
}
.carousel-caption {
  padding-top: 50px;
}
