
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
/**/


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  /*
  font-family: "Lato";
  */
}
/*
h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  -webkit-text-stroke: 1.5px black;
  -webkit-text-fill-color: white;
  font-size: 2.25em;
}
*/
/*
@-webkit-keyframes fill {
  from { -webkit-text-fill-color: steelblue; }
  to { -webkit-text-fill-color: slategrey; }
}*/


img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}


$primary: purple;
$danger: red;
$success: #5F81E3;

@import "bootstrap/scss/bootstrap.scss";